// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'


// ---------------------------------------------------------------------------
// Fonction ajoutant l'événement 'click' qui appellera la fonction
// 'scrollToBlock'
// ---------------------------------------------------------------------------
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// ---------------------------------------------------------------------------
// Fonction exécutant l'animation du scroll vers son bloc
// ---------------------------------------------------------------------------
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}


// ---------------------------------------------------------------------------
// Fonction permettant d'ajuster le height 100vh en mobile
// ---------------------------------------------------------------------------
export const viewportHeight = () => {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  let vh = window.innerHeight
  
  // const onResize = () => {
    if (width != (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)) {
      width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      vh = window.innerHeight
      document.documentElement.style.setProperty('--viewport-height', `${vh}px`)
    }
  // }

  document.documentElement.style.setProperty('--viewport-height', `${vh}px`)

  // OBSERVER.add({ name: 'viewport-height', event: 'resize', function: onResize })
  // OBSERVER.on('viewport-height')
}

export function changeSelect(select) {
    $('.dropdown-option').on('click', function() {
      $(select).trigger('change')
    });
}


export function googleMap() {
  console.log('hello map')
  var getUrl = window.location.origin
  var mapOption = {
    center: new google.maps.LatLng(45.5935487,-73.6632552),
    zoom: 17,
    styles: [
      {"featureType": "water","elementType": "geometry","stylers": [{"color": "#e9e9e9"},{"lightness": 17}]},
      {"featureType": "landscape","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 20}]},
      {"featureType": "road.highway","elementType": "geometry.fill","stylers": [{"color": "#ffffff"},{"lightness": 17}]},
      {"featureType": "road.highway","elementType": "geometry.stroke","stylers": [{"color": "#ffffff"},{"lightness": 29},{"weight": 0.2}]},
      {"featureType": "road.arterial","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 18}]},
      {"featureType": "road.local","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 16}]},
      {"featureType": "poi","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 21}]},
      {"featureType": "poi.park","elementType": "geometry","stylers": [{"color": "#dedede"},{"lightness": 21}]},
      {"elementType": "labels.text.stroke","stylers": [{"visibility": "on"},{"color": "#ffffff"},{"lightness": 16}]},
      {"elementType": "labels.text.fill","stylers": [{"saturation": 36},{"color": "#333333"},{"lightness": 40}]},
      {"elementType": "labels.icon","stylers": [{"visibility": "off"}]},
      {"featureType": "transit","elementType": "geometry","stylers": [{"color": "#f2f2f2"},{"lightness": 19}]},
      {"featureType": "administrative","elementType": "geometry.fill","stylers": [{"color": "#fefefe"},{"lightness": 20}]},
      {"featureType": "administrative","elementType": "geometry.stroke","stylers": [{"color": "#fefefe"},{"lightness": 17},{"weight": 1.2}]}
    ]
  }

  var googleMap = new google.maps.Map(document.getElementById('map'), mapOption)

  var marker = new google.maps.Marker({
    position: {lat: 45.5935487, lng: -73.6632552},
    map: googleMap,
    icon: getUrl+'/themes/core/assets/medias/images/icons/marker-map.svg'
  })
}