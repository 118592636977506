// |--------------------------------------------------------------------------
// | Helper
// |--------------------------------------------------------------------------
// |
// | Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Ajoute un target blank sur tous les liens externes et PDF et empêche une
// transition si la destination est la page actuelle
// ---------------------------------------------------------------------------
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// ---------------------------------------------------------------------------
// Retourne la position d'un élément dans le DOM
// ---------------------------------------------------------------------------
export const getElementOffset = (options) => {
  let box = typeof options.element === 'string' ? document.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}


// ---------------------------------------------------------------------------
// Détecte si un élément est visible dans le viewport et retourne une
// condition
// ---------------------------------------------------------------------------
export function isVisible(element) {
  const rect = element.getBoundingClientRect()

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth  || document.documentElement.clientWidth

  const top = rect.top <= viewportHeight
  const right = rect.right >= 0 - viewportWidth
  const bottom = rect.bottom >= 0 - viewportHeight
  const left = rect.left <= viewportWidth

  return top && right && bottom && left
}


// ---------------------------------------------------------------------------
// Retourne si l'utilisateur est sur mobile ou pas
// ---------------------------------------------------------------------------
export const isMobile = () => {
  let isMobile = false
  
  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true
  
  return isMobile
}


// ---------------------------------------------------------------------------
// Retire tous les effets d'over sur le mobile
// ---------------------------------------------------------------------------
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (error) { console.log(error) }
  }
}