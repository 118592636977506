// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Overlay from '../../libraries/overlay.js'
import { Accordions } from '../classes/Accordions.js'
import { OBSERVER } from '../main.js'


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay du menu mobile
// ---------------------------------------------------------------------------
export const overlayDropdown = () => {
  new Overlay({
    name: 'dropdown',
    mouseover: {
      buttons: {
        delay: 200,
        open: '[data-js="open-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"]',
      }
    },
    click: {
      buttons: {
        close: 'a[href]',
      }
    },
    animations: {
      selector: '[data-overlay="dropdown"]',
      addTransition: false,
      styles: [
        {
          property: 'height',
          value: '100%'
         
        }
      ]
    },
    options: {
      speed: 500,
    }
  }).init()
}


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay du menu mobile
// ---------------------------------------------------------------------------
export const overlayMenu = () => {
  new Overlay({
    name: 'menu',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
      }
    },
    options: {
      speed: 700,
      goToSelector: 'html, body',
    }
  }).init()

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu',
    scrollToAccordion: false
  })

  const onClose = () => {
    level1.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMobileMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.on('overlayMobileMenu')
}