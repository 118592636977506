
export function cfEmailDecode()
{
    var emailProtectionUrl = '/cdn-cgi/l/email-protection#'
    var protectionClassSelector = '.__cf_email__'
    var dataCfmailAttribute = 'data-cfemail'
    var virtualDiv = document.createElement('div')

    function cf_logError(message)
    {
        try {
          // Elegantly handle log messages
            if ('undefined' == typeof console) {
                return
            }
            'error' in console ? console.error(message) : console.log(message)
        } catch (e) {
        }
    }

    function cf_getEscapedAddress(address)
    {
      // Create link inside div
        virtualDiv.innerHTML = '<a href="' + address.replace(/"/g, '&quot;') + '"></a>'
      // Return created link address
        return virtualDiv.childNodes[0].getAttribute('href') || ''
    }

    function cf_hexToInt(text, offset)
    {
      // Get two characters from offset
        var hexstr = text.substr(offset, 2)
      // Pretend to hexadecimal value
        return parseInt(hexstr, 16)
    }

    function cf_decodeData(enc, keyOffset)
    {
        for (var dec = '', key = cf_hexToInt(enc, keyOffset), i = keyOffset + 2; i < enc.length; i += 2) {
          // Decode with xor
            var l = cf_hexToInt(enc, i) ^ key
          // Build string
            dec += String.fromCharCode(l)
        }
        try {
            dec = decodeURIComponent(escape(dec))
        } catch (e) {
            logError(e)
        }
        return cf_getEscapedAddress(dec)
    }

    function cf_restoreProtectionEmailLink(element)
    {
      // Iterate through links
        for (var allLinks = element.querySelectorAll('a'), i = 0; i < allLinks.length; i++) {
            try {
                var link = allLinks[i],
                protectionUrlIndex = link.href.indexOf(emailProtectionUrl)
                if (protectionUrlIndex > -1) {  // Has url
                    link.href = 'mailto:' + cf_decodeData(
                        link.href,
                        protectionUrlIndex + emailProtectionUrl.length /* Index after protection URL */
                    )
                }
            } catch (e) {
                logError(e)
            }
        }
    }

    function cf_restoreEmailTextNode(element)
    {
        for (var protectedEmails = element.querySelectorAll(protectionClassSelector), i = 0; i < protectedEmails.length; i++) {
            try {
                var protectedEmail = protectedEmails[i],
                protectedEmailParent = protectedEmail.parentNode,
                dataCfemail = protectedEmail.getAttribute(dataCfmailAttribute)
                if (dataCfemail) {
                    var email = cf_decodeData(dataCfemail, 0),
                    emailText = document.createTextNode(email)
                  // Replace protected email text to origianl
                    protectedEmailParent.replaceChild(emailText, protectedEmail)
                }
            } catch (e) {
                logError(e)
            }
        }
    }

    function cf_templateRecursiveApply(element)
    {
      // Find all template tags, initialize from templates content DOM
        for (var allTemplateTags = element.querySelectorAll('template'), i = 0; i < allTemplateTags.length; i++) {
            try {
                cf_initialize(allTemplateTags[i].content)
            } catch (e) {
                logError(e)
            }
        }
    }

    function cf_initialize(element)
    {
        try {
            cf_restoreProtectionEmailLink(element)
            cf_restoreEmailTextNode(element)
            cf_templateRecursiveApply(element)
        } catch (e) {
            logError(e)
        }
    }

    cf_initialize(document)
    //var self = document.currentScript || document.scripts[document.scripts.length - 1];
    //self.parentNode.removeChild(self)
}
