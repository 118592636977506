// |--------------------------------------------------------------------------
// | Formulaires et validation
// |--------------------------------------------------------------------------
// |
// | Validation, messages d'erreur, formatage de champs et divers à propos des
// | formulaires, c'est ici !
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from '../main'
import { isMobile } from './helper'


// ---------------------------------------------------------------------------
// Ajoute un attribut en considérant si le input est plein ou vide
// ---------------------------------------------------------------------------
export const inputsAndTextareaLabel = () => {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], textarea',
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({ name: 'inputsAndTextareaLabel', event: 'input', target: elementsString, function: setValue })
  OBSERVER.on('inputsAndTextareaLabel')
}



// ---------------------------------------------------------------------------
// Permet de supprimer le contenu d'un input
// ---------------------------------------------------------------------------
export const clearInput = () => {
  const onClick = (e) => {
    
    let input = e.currentTarget.parentNode.querySelector('input')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({ name: 'clearInput', event: 'click', target: '.js-clear-input', function: onClick })
  OBSERVER.on('clearInput')
}


// ---------------------------------------------------------------------------
// Création du custom select (doc: https://github.com/pytesNET/tail.select)
// ---------------------------------------------------------------------------
export const select = () => {
  if (!isMobile())
    tail.select('select', { animate: false })
  
  let i
  const selectLabel = document.querySelectorAll('.tail-select'),
    selectLabelLength = selectLabel.length,
    pointer = document.createElement('div')
  pointer.classList.add('label-pointer')
  
  for (i=0; i<selectLabelLength; i++)
    selectLabel[i].appendChild(pointer)
  
}



// ---------------------------------------------------------------------------
// Modification de la hauteur d'un textarea selon son contenu
// ---------------------------------------------------------------------------
export const textareaHeight = () => {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 42 ? 42 : e.currentTarget.scrollHeight}px`
  }
  
  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    function: onInput
  })
  
  OBSERVER.on('textareaHeight')
}


// ---------------------------------------------------------------------------
// Permet de formatter les champs téléphone
// ---------------------------------------------------------------------------
export function formatPhone() {
  $('[data-js="mask-phone"]').mask('000 000-0000')
}


export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {

      switch(element[0].getAttribute('type')) {
        // Exeption pour les radio buttons ou checkbox
        case 'radio':
        case 'checkbox':
          error.appendTo(element.parent().parent().parent().find('.js-form-error'))
          break;
        // Types texts et autres
        default:
          error.appendTo(element.parent().find('.js-form-error'))
      }


    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
}

export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPostalCode(element) {
  $(element).mask('Z0Z 0Z0', {
    translation: {
      'Z': {
        pattern: /[A-Z, a-z]/
      }
    }
  })
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Validation du formulaire de contact'
export function formContact() {
  validationEmail()

  var m = getMessages()

  var rules = {
    name:          { required: true },
    email:         { required: true, courriel: true },
    phone:         { minlength: 12 },
    message:       { required: true }
  }
  var messages = {
    name:          { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { minlength: m.phone },
    message:       { required: m.required }
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}