// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { Accordions } from '../classes/Accordions'
import { OBSERVER } from '../main'


// ---------------------------------------------------------------------------
// Fonction gérant le slider de la bannière
// ---------------------------------------------------------------------------
export const swiperBanner = () => {
  const swiper = new Swiper('[data-swiper="home-banner"]', {
    effect: 'fade',
    threshold: 10,
    pagination: {
      el: '[data-pagination="home-banner"]',
      type: 'bullets',
      clickable: true
    },
    autoplay: {
      delay: 7000,
    },
  })
}


// ---------------------------------------------------------------------------
// Fonction gérant le slider de l'équipe
// ---------------------------------------------------------------------------
export const swiperTeam = () => {
  let accordions

  const goToSlide = e => swiper.slideTo(e.currentTarget.getAttribute('data-team-index'))

  OBSERVER.add({
    name: 'swiperTeam',
    event: 'click',
    target: '[data-team-index]',
    function: goToSlide
  })

  const swiper = new Swiper('[data-js="team-swiper"]', {
    slidesPerView: 1,
    // slidesPerGroup: 1,
    // loop: true,
    simulateTouch: false,
    effect: 'fade',
    threshold: 10,
    autoplay: {
      delay: 4000,
      pauseOnMouseEnter: true
    },
    on: {
      afterInit: () => {
        OBSERVER.on('swiperTeam')
        accordions = new Accordions({
          containerClass: 'js-accordions-bio',
          scrollToAccordion: false,
          duration: 500
        })
      },
      slideChange: () => accordions.closeAll()
    }
  })
}