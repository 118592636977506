// |--------------------------------------------------------------------------
// | Transition avec barba et gestion des vues
// |--------------------------------------------------------------------------
// |
// | C'est ici que les transitions entre les pages sont gérées, on y retrouve
// | les différentes vues spécifiques à chaque page. Les vues sont également
// | définies ici.
// |--------------------------------------------------------------------------

import barba from '@barba/core'
import Overlay from '../libraries/overlay.js'
import { Accordions } from './classes/Accordions.js'
import { backToTopAndLinks} from './functions/back-to-top-and-links.js'
import { formatPhone, select, textareaHeight, formContact } from './functions/form.js'
import { clickToScrollToBlock, viewportHeight, changeSelect, googleMap } from './functions/functions.js'
import { removeHoverOnMobile } from './functions/helper.js'
import { overlayDropdown, overlayMenu } from './functions/overlays.js'
import { swiperBanner, swiperTeam } from './functions/sliders.js'
import { OBSERVER, SCROLLFIRE } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { cfEmailDecode } from './functions/cf_email_decoder.js'

export const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

      backToTopAndLinks()
      overlayDropdown()
      overlayMenu()
      removeHoverOnMobile()
      viewportHeight()
      SCROLLFIRE.init()
      cfEmailDecode()
  })
  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })
  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })
  barba.init({

    // Options
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions
    transitions: [
      defaultTransition()
    ],

    // Views
    views: [
      {
        namespace: 'standard',
        beforeEnter() {},
        afterLeave () {}
      },
      {
        namespace: 'home',
        beforeEnter() {
          swiperBanner()
          swiperTeam()
          clickToScrollToBlock({ selector: '[data-js="go-to-selector"]', scrollTo: '[data-js="twin-showcases"]' })
        },
        afterLeave () {}
      },
      {
        namespace: 'experience',
        beforeEnter() {
          clickToScrollToBlock({ selector: '[data-js="go-to-selector"]', scrollTo: '[data-js="intro"]' })
        },
        afterLeave () {}
      },
      {
        namespace: 'team',
        beforeEnter() {
          new Accordions({ containerClass: 'js-accordions-bio', scrollToAccordion: false, duration: 500 })
        },
        afterLeave () {}
      },
      {
        namespace: 'expertises-list',
        beforeEnter() {},
        afterLeave () {}
      },
      {
        namespace: 'expertise-detail',
        beforeEnter() {
          new Accordions()
          clickToScrollToBlock({ selector: '[data-js="go-to-selector"]', scrollTo: '[data-js="expertise"]' })
        },
        afterLeave () {}
      },
      {
        namespace: 'news-list',
        beforeEnter() {
          select()
          changeSelect('#form-events-categories')
        },
        afterLeave () {}
      },
      {
        namespace: 'news-detail',
        beforeEnter() {
          select()
          changeSelect('#form-events-categories')
        },
        afterLeave () {}
      },
      {
        namespace: 'contact',
        beforeEnter() {
          textareaHeight()
          formatPhone()
          formContact()
          googleMap()
        },
        afterLeave () {}
      },
      {
        namespace: 'privacy-policy',
        beforeEnter() {},
        afterLeave () {}
      },
    ]
  })
}
